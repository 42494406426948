import React from "react";
import { PickingListComponent, PickingListOption } from "@emisgroup/clint-templates-common";
import { v4 as uuid } from "uuid";
import { ParametersForPropertyUpdate } from "../types";
import { ListBuilder, ItemWithKey } from "../uiComponents/listBuilder";
import ListBuilderOptionGrid from "../uiComponents/listBuilderOptionGrid";
import { capitalise } from "../utils/componentUtils";

type ValueListPropertyEntryProps = {
    isReadOnly: boolean;
    isMandatory: boolean;
    component: PickingListComponent;
    name: string;
    descriptionText: string;
    validity: { isValid: boolean; message?: string };
    onPropertyUpdate: (params: ParametersForPropertyUpdate) => void;
};

const ValueListPropertyEntry = ({
    component,
    name,
    descriptionText,
    isReadOnly,
    isMandatory,
    validity,
    onPropertyUpdate,
}: ValueListPropertyEntryProps) => {
    const { hasValues, hasFilingData } = component;
    const [config, setConfig] = React.useState({ hasValues, hasFilingData });
    const [items, setItems] = React.useState<ItemWithKey[]>(
        () => component[name].map(i => ({ value: i, key: uuid() })) || [],
    );

    const handleItemsUpdate = (updatedItems: ItemWithKey[], updateProperty: boolean) => {
        if (updateProperty) {
            onPropertyUpdate({
                item: { ...component, hasValues: config.hasValues, hasFilingData: config.hasFilingData },
                propertyName: name,
                propertyValue: updatedItems.map(i => i.value),
            });
        }

        setItems(updatedItems);
    };

    const handleOptionToggle = (propertyName: string, dataPropertyName: string) => (propertyValue: boolean) => {
        const removePropertyFromOption = (option: PickingListOption) => {
            const { [dataPropertyName]: propToRemove, ...remainingProps } = option as any;
            return remainingProps;
        };

        setConfig({ ...config, [propertyName]: propertyValue });
        if (!propertyValue) {
            setItems(items.map(item => ({ ...item, value: removePropertyFromOption(item.value) })));
        }
    };

    const handleExclusiveCheckboxToggle = (checked: boolean) => {
        component.exclusiveCheckbox = checked;
        onPropertyUpdate({
            item: component,
            propertyName: name,
            propertyValue: component[name],
        });
    };

    const validityClass = validity.isValid ? "" : "invalid-property";

    return (
        <>
            <div className="property-name value-list-property" aria-label={descriptionText}>
                <span
                    className={`sidebar-row ${
                        isMandatory ? "eui-form-element__label--mandatory" : ""
                    } ${validityClass}`}
                >
                    {capitalise(descriptionText)}
                </span>
            </div>
            {items.length > 0 && (
                <ListBuilderOptionGrid
                    hasValues={Boolean(config.hasValues)}
                    hasFilingData={Boolean(config.hasFilingData)}
                >
                    <span className="list-builder-option-header label-header">Option label</span>
                    {config.hasValues && <span className="list-builder-option-header value-header">Value</span>}
                    {config.hasFilingData && <span className="list-builder-option-header data-header">Data</span>}
                </ListBuilderOptionGrid>
            )}

            <ListBuilder
                componentId={component.id}
                items={items}
                isReadOnly={isReadOnly}
                hasValues={Boolean(config.hasValues)}
                hasFilingData={Boolean(config.hasFilingData)}
                updateItems={handleItemsUpdate}
                validity={validity}
                onHasValuesToggle={handleOptionToggle("hasValues", "value")}
                onHasFilingDataToggle={handleOptionToggle("hasFilingData", "data")}
                exclusiveCheckbox={component.exclusiveCheckbox}
                onExclusiveCheckboxToggle={handleExclusiveCheckboxToggle}
                displayStyle={component.displayStyle}
            />
        </>
    );
};

export default ValueListPropertyEntry;
