import * as React from "react";
import { Notification, NotificationContainer } from "@emisgroup/ui-notification";
import "./timedNotification.css";

type TimedNotificationProps = {
    type: "success" | "info";
    text: string;
    milliseconds: number;
    onTimeout: () => void;
};

const TimedNotification = ({ type, text, milliseconds, onTimeout }: TimedNotificationProps) => {
    React.useEffect(() => {
        let notificationTimer: NodeJS.Timeout | null = null;
        notificationTimer = setTimeout(onTimeout, milliseconds) as unknown as NodeJS.Timeout;

        return () => {
            if (notificationTimer) {
                clearTimeout(notificationTimer);
            }
        };
    }, [milliseconds, onTimeout]);
    return (
        <NotificationContainer className="timed-notification">
            <Notification variant={type}>
                <Notification.Content> {text} </Notification.Content>
            </Notification>
        </NotificationContainer>
    );
};

export default TimedNotification;
