import { Button } from "@emisgroup/ui-button";
import { ButtonProps } from "../types";
import "./dialogButton.css";

export function DialogButton({ button }: { button: ButtonProps }) {
    const { ariaLabel, label, disabled, onClick, iconClasses, isPrimary, isSecondary, isDanger } = button;
    let buttonStyle: any = undefined;
    if (isPrimary) {
        buttonStyle = "filled";
    } else if (isDanger) {
        buttonStyle = "danger";
    }

    return (
        <Button
            key={label}
            type="button"
            variant={buttonStyle}
            className={`eui-button eui-button--${iconClasses}`}
            onClick={onClick}
            ariaLabel={ariaLabel}
            disabled={disabled ?? false}
            borderless={isSecondary}
        >
            {label}
        </Button>
    );
}
