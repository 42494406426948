/* eslint-disable react/style-prop-object */
import * as React from "react";
import { Dialog, DialogContent, DialogFooter } from "@emisgroup/ui-kit-react";
import { Button, ButtonGroup } from "@emisgroup/ui-button";
import { ShowPassword } from "@emisgroup/icons-react";
import { useTranslation } from "@emisgroup/application-intl";
import VisibilityRuleViewer from "./visibilityRuleViewer";

import "./visibilityRuleIndicator.css";

type RuleViewerDialogProps = { children: React.ReactNode | React.ReactNodeArray; onClose: () => void };
function RuleViewerDialog({ children, onClose }: RuleViewerDialogProps) {
    return (
        <Dialog
            open={true}
            onClose={onClose}
            title="Visibility rule viewer"
            className="auto-sized-dialog"
            disableDismiss
        >
            <DialogContent>{children}</DialogContent>
            <DialogFooter>
                <ButtonGroup>
                    <Button data-testid="close-viewer" type="button" variant="filled" onClick={onClose}>
                        Close
                    </Button>
                </ButtonGroup>
            </DialogFooter>
        </Dialog>
    );
}

type VisibilityRuleIndicatorProps = { componentId: string; optionValue?: string; dataTestId?: string };
export default function VisibilityRuleIndicator({
    componentId,
    optionValue,
    dataTestId,
}: VisibilityRuleIndicatorProps) {
    const [showRuleViewer, setShowRuleViewer] = React.useState(false);
    const { t } = useTranslation();

    return (
        <>
            <button
                type="button"
                className="rules-button"
                data-testid={dataTestId}
                onClick={() => setShowRuleViewer(true)}
            >
                <ShowPassword title={t("propertyInspector.affectsVisibilityRules")} />
            </button>
            {showRuleViewer && (
                <RuleViewerDialog onClose={() => setShowRuleViewer(false)}>
                    <VisibilityRuleViewer componentId={componentId} optionValue={optionValue} />
                </RuleViewerDialog>
            )}
        </>
    );
}
