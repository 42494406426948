/* eslint-disable react/style-prop-object */
import React from "react";
import { Checkbox } from "@emisgroup/ui-kit-react";
import { Button } from "@emisgroup/ui-button";
import {
    AuthContext,
    ConfigContext,
    CODE_EXTRA_INFORMATION_PROPERTIES,
    CanvasItem,
    Code,
} from "@emisgroup/clint-templates-common";
import { useTranslation } from "@emisgroup/application-intl";
import { InlineCodeBasket } from "../uiComponents/codeBasket";
import CodePickerDialog from "../uiComponents/codePickerDialog";
import { ParametersForPropertyUpdate } from "../types";
import { Picker } from "@emisgroup/icons-react";

type CodeListPropertyEntryProps = {
    isReadOnly: boolean;
    isMandatory: boolean;
    component: CanvasItem;
    name: string;
    validity?: { isValid: boolean; message?: string };
    onPropertyUpdate: (params: ParametersForPropertyUpdate) => void;
};

const CodeListPropertyEntry = ({
    component,
    name,
    isReadOnly,
    isMandatory,
    validity = { isValid: true },
    onPropertyUpdate,
}: CodeListPropertyEntryProps) => {
    const { t } = useTranslation();
    const [showingPicker, setShowingPicker] = React.useState(false);
    const [showingDetails, setShowingDetails] = React.useState(false);
    const { features } = React.useContext(ConfigContext);
    const { getBearerToken } = React.useContext(AuthContext);
    const bearerTokenRef = React.useRef("");
    React.useEffect(() => {
        const updateBearerToken = async () => {
            bearerTokenRef.current = await getBearerToken();
        };
        updateBearerToken();
    }, [getBearerToken]);

    const handleCodeListSelect = (selectedCodes: Code[]) => {
        onPropertyUpdate({ item: component, propertyName: name, propertyValue: selectedCodes });
        setShowingPicker(false);
    };
    const codes = component[name] || [];
    return (
        <>
            <div className="sidebar-right code-list">
                <div
                    className={`sidebar-row sidebar-row-header${
                        isMandatory ? " eui-form-element__label--mandatory" : ""
                    }${!validity.isValid ? " invalid-property" : ""}`}
                >
                    <div className={`${isMandatory ? "sidebar-row-header--mandatory" : ""}`}>
                        {t("components.pickingList.codes")}
                    </div>
                </div>
                {features[CODE_EXTRA_INFORMATION_PROPERTIES].length > 0 && (
                    <Checkbox
                        checked={showingDetails}
                        onChange={ev => setShowingDetails(ev.currentTarget.checked)}
                        labelText={t("codeList.showIds")}
                    />
                )}
                <div className={`code-list-display ${!validity.isValid ? "invalid-property-value" : ""}`}>
                    <div className="list-of-codes">
                        {codes.length > 0 && (
                            <InlineCodeBasket
                                codes={codes}
                                updateCodes={handleCodeListSelect}
                                includeDetails={showingDetails}
                                isReadOnly={isReadOnly}
                            />
                        )}
                    </div>
                    {!isReadOnly && (
                        <Button
                            variant="filled"
                            ariaLabel={t("codeList.clickToSelectCodes")}
                            title={t("codeList.selectCodes")}
                            onClick={() => setShowingPicker(true)}
                        >
                            <Picker title="" />
                        </Button>
                    )}
                </div>
                {!validity.isValid && validity.message && (
                    <div style={{ marginTop: 0 }} className="eui-form-element__error">
                        {t(validity.message)}
                    </div>
                )}
            </div>
            {showingPicker && (
                <CodePickerDialog
                    onClose={() => setShowingPicker(false)}
                    initialCodes={codes}
                    onCodeListSelect={handleCodeListSelect}
                    multiSelection={true}
                    bearerToken={bearerTokenRef.current}
                />
            )}
        </>
    );
};

export default CodeListPropertyEntry;
