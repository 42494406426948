/* eslint-disable react/style-prop-object */
import React from "react";
import { Add, InfoWarningMono } from "@emisgroup/icons-react";
import { useTranslation } from "@emisgroup/application-intl";
import { ConfigContext, PERSPECTIVES } from "@emisgroup/clint-templates-common";
import { Dialog, DialogContent } from "@emisgroup/ui-kit-react";
import { Button } from "@emisgroup/ui-button";
import {
    buildPerspectivesKey,
    expandPerspectivesKey,
    IPerspective,
    PerspectivesDefinition,
} from "@emisgroup/clint-templates-perspectives/lib";
import {
    getStateValidationErrors,
    getUnselectedStates,
    getPerspectiveName,
    NamedPerspective,
    PerspectiveState,
} from "../utils/perspectiveUtils";
import PerspectiveEdit from "./perspectiveEdit";
import "./perspectives.css";

export type PerspectivesAdvancedProps = {
    perspectives: NamedPerspective[];
    onUpdate: (perspectives: IPerspective[] | undefined) => void;
    onClose: () => void;
};

const newPerspective: PerspectiveState = {
    selectedKeys: [
        "runner-patient",
        "runner-healthcare",
        "referralReport-body",
        "referralReport-patient",
        "referralReport-healthcare",
    ],
};

const getPerspectivesOrDefault = (perspectives: PerspectiveState[]): PerspectiveState[] =>
    perspectives.length === 0 ? [newPerspective] : perspectives;

const getInitialPerspectives = (
    editablePerspectives: NamedPerspective[],
    perspectiveDefinitions: PerspectivesDefinition[],
): PerspectiveState[] =>
    getPerspectivesOrDefault(
        editablePerspectives.map(perspective => ({
            ...perspective,
            selectedKeys: expandPerspectivesKey(perspectiveDefinitions, perspective.key),
        })),
    );

const PerspectiveSelectionItem = (props: {
    states: PerspectiveState[];
    index: number;
    selectedIndex: number;
    selectPerspective: () => void;
    generatePerspectiveName: (state: PerspectiveState) => string;
}) => {
    const { t } = useTranslation();
    const { states, index, selectedIndex, selectPerspective, generatePerspectiveName } = props;
    const validationErrors = getStateValidationErrors(t, index, states);
    const isInvalid = validationErrors.length > 0;
    return (
        <div
            className={`perspective-button${index === selectedIndex ? " is-selected" : ""}${
                isInvalid ? " is-invalid" : ""
            }`}
            role="none"
            title={validationErrors.join(",")}
            onClick={selectPerspective}
        >
            {generatePerspectiveName(states[index])}
            {isInvalid && <InfoWarningMono className="perspective-button-icon" title="" color="danger" />}
        </div>
    );
};

const PerspectiveSelect = (props: {
    states: PerspectiveState[];
    selectedIndex: number;
    generatePerspectiveName: (state: PerspectiveState) => string;
    selectPerspective: (index: number) => void;
    addPerspective: () => void;
}) => {
    const { t } = useTranslation();
    const { states, selectedIndex, generatePerspectiveName, selectPerspective, addPerspective } = props;
    return (
        <div className="perspective-select">
            <div className="perspectives-custom">
                {React.Children.toArray(
                    states.map((_, i) => (
                        <PerspectiveSelectionItem
                            states={states}
                            index={i}
                            selectedIndex={selectedIndex}
                            selectPerspective={() => selectPerspective(i)}
                            generatePerspectiveName={generatePerspectiveName}
                        />
                    )),
                )}
            </div>
            <div className="perspective-button" role="none" onClick={addPerspective}>
                {t("perspectives.addNew")}
                <Add className="perspective-button-icon" title="add" />
            </div>
        </div>
    );
};

const PerspectivesAdvanced = (props: PerspectivesAdvancedProps) => {
    const { t } = useTranslation();
    const { features } = React.useContext(ConfigContext);
    const definitions = features[PERSPECTIVES];

    const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
    const [perspectiveStates, setPerspectiveStates] = React.useState<PerspectiveState[]>(
        getInitialPerspectives(props.perspectives, definitions),
    );

    const addPerspective = () => {
        setPerspectiveStates(perspectiveStates.concat(newPerspective));
        setSelectedIndex(perspectiveStates.length);
    };

    const deletePerspective = React.useCallback(() => {
        const newPerspectives = getUnselectedStates(selectedIndex, perspectiveStates);
        setPerspectiveStates(getPerspectivesOrDefault(newPerspectives));
        setSelectedIndex(0);
    }, [selectedIndex, setPerspectiveStates, perspectiveStates]);

    const generatePerspectiveName = (state: PerspectiveState) =>
        getPerspectiveName(t, { ...state, key: buildPerspectivesKey(definitions, state.selectedKeys) });

    const updatePerspective = React.useCallback(
        (state: PerspectiveState) =>
            setPerspectiveStates(perspectiveStates.map((p, i) => (i === selectedIndex ? state : p))),
        [selectedIndex, setPerspectiveStates, perspectiveStates],
    );

    const onSaveClick = () => {
        const firstInvalidPerspectiveIndex = perspectiveStates.findIndex(
            (_, i) => getStateValidationErrors(t, i, perspectiveStates).length > 0,
        );
        if (firstInvalidPerspectiveIndex === -1) {
            props.onUpdate(
                perspectiveStates.map(p => ({
                    ...p,
                    key: buildPerspectivesKey(definitions, p.selectedKeys),
                    selectedKeys: undefined,
                })),
            );
            props.onClose();
        } else {
            setSelectedIndex(firstInvalidPerspectiveIndex);
        }
    };
    const perspectivesLabel = t("perspectives.perspectives");
    return (
        <Dialog open={true} onClose={props.onClose} title={perspectivesLabel} disableDismiss={true}>
            <DialogContent className="perspectives-advanced">
                <PerspectiveSelect
                    selectedIndex={selectedIndex}
                    generatePerspectiveName={generatePerspectiveName}
                    states={perspectiveStates}
                    selectPerspective={setSelectedIndex}
                    addPerspective={addPerspective}
                />
                <PerspectiveEdit
                    states={perspectiveStates}
                    selectedIndex={selectedIndex}
                    updatePerspective={updatePerspective}
                    perspectiveSettings={definitions}
                    generatePerspectiveName={generatePerspectiveName}
                    deletePerspective={deletePerspective}
                />
                <div className="perspectives-advanced-buttons">
                    <Button className="perspectives-advanced-button" onClick={props.onClose}>
                        {t("perspectives.close")}
                    </Button>
                    <Button className="perspectives-advanced-button" variant="filled" onClick={onSaveClick}>
                        {t("perspectives.saveAndClose")}
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default PerspectivesAdvanced;
