import React from "react";
import { Button, ButtonGroup } from "@emisgroup/ui-button";
import { ActionButton, ActionButtonStyle } from "../types";

type ActionButtonsProps = {
    actionButtons: ActionButton[];
    disabled?: boolean;
    onRaiseAction?: (actionButton: ActionButton) => void;
};
const ActionButtons = ({ actionButtons, disabled, onRaiseAction }: ActionButtonsProps) => {
    return (
        <div data-testid="action-buttons" className="canvas-buttons">
            <ButtonGroup>
                {React.Children.toArray(
                    actionButtons.map(actionButton => {
                        let buttonStyle: any = undefined;
                        if (actionButton.style === ActionButtonStyle.PRIMARY) {
                            buttonStyle = "filled";
                        }
                        return (
                            <Button
                                key={actionButton.label}
                                className="canvas-button"
                                disabled={disabled === true}
                                data-testid={`${actionButton.action}-event-button`}
                                ariaLabel={actionButton.label}
                                variant={buttonStyle}
                                onClick={onRaiseAction ? () => onRaiseAction(actionButton) : undefined}
                                borderless={actionButton.style === ActionButtonStyle.SECONDARY}
                            >
                                {actionButton.label}
                            </Button>
                        );
                    }),
                )}
            </ButtonGroup>
        </div>
    );
};

export default ActionButtons;
