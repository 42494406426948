import React from "react";
import { Button } from "@emisgroup/ui-button";
import { ChevronUp } from "@emisgroup/icons-react";
import { AppModeContext, ConfigContext, TemplateContext, AppMode } from "@emisgroup/clint-templates-common";
import { useTranslation } from "@emisgroup/application-intl";
import TemplateComponentSection from "./templateComponentSection";
import componentSectionList from "./componentSectionList";
import "./sidebar.css";

const Sidebar = () => {
    const { t } = useTranslation();
    const [showFlyout, setShowFlyout] = React.useState(false);
    const { mode } = React.useContext(AppModeContext);
    const { templateDefinition } = React.useContext(TemplateContext);
    const { features } = React.useContext(ConfigContext);
    const paletteStyle = showFlyout ? { height: "100%" } : {};
    const componentPalette = (
        <div
            data-testid="sidebar-components"
            className="sidebar-left"
            style={paletteStyle}
            title={t("templates.componentsYouCanAdd")}
        >
            <ul>
                {t("templates.availableComponents.title")}
                {showFlyout && (
                    <button
                        type="button"
                        className="eui-dialog__close"
                        style={{ float: "right" }}
                        onClick={() => setShowFlyout(false)}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                )}
            </ul>
            {componentSectionList(features).map(componentSectionData => (
                <TemplateComponentSection componentSectionData={componentSectionData} key={componentSectionData.name} />
            ))}
        </div>
    );

    const flyout = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        const clickOutOfFlyout = ev => {
            if (flyout.current && !flyout.current.contains(ev.target)) {
                setShowFlyout(false);
            }
        };

        const updateFlyoutSize = () => {
            const flyoutDiv = flyout.current as HTMLDivElement;
            const { top } = flyoutDiv.getBoundingClientRect();
            flyoutDiv.style.height = `${window.innerHeight - top}px`;
        };

        if (showFlyout) {
            updateFlyoutSize();
            window.addEventListener("mousedown", clickOutOfFlyout);
            window.addEventListener("resize", updateFlyoutSize);
        }

        return () => {
            window.removeEventListener("mousedown", clickOutOfFlyout);
            window.removeEventListener("resize", updateFlyoutSize);
        };
    }, [showFlyout]);

    React.useEffect(() => {
        setShowFlyout(false);
    }, [templateDefinition]);

    if (mode !== AppMode.EDIT) {
        return null;
    }

    return (
        <>
            <div className="sidebar-left-container d-none d-lg-block">{componentPalette}</div>
            {showFlyout && (
                <div ref={flyout} className="sidebar-left-container flyout-sidebar d-block d-lg-none">
                    {componentPalette}
                </div>
            )}
            {!showFlyout && (
                <Button
                    // eslint-disable-next-line react/style-prop-object
                    variant="filled"
                    className="d-lg-none component-palette-popup"
                    onClick={() => setShowFlyout(true)}
                >
                    <ChevronUp title={t("templates.viewComponentPalette")} />
                    <span>{t("templates.componentPalette")}</span>
                </Button>
            )}
        </>
    );
};

export default Sidebar;
