import React from "react";
import { Alert } from "@emisgroup/ui-alert";
import { AlertSize, AlertType, ButtonProps } from "../types";
import { getButtonGroup } from "./genericDialog";

interface AlertProps {
    type?: AlertType;
    size?: AlertSize;
    title: string;
    buttons: ButtonProps[];
    onClose: () => void;
    children: React.ReactNode;
}

const AlertDialog = ({
    type = AlertType.WARNING,
    size = AlertSize.MEDIUM,
    title,
    buttons,
    onClose,
    children,
}: AlertProps) => {
    return (
        <div role="none" onClick={evt => evt.stopPropagation()}>
            <Alert
                open={true}
                className="auto-sized-dialog"
                onOpenChange={onClose}
                title={title}
                buttons={getButtonGroup(buttons)}
                type={type}
                size={size}
            >
                {children}
            </Alert>
        </div>
    );
};

export default AlertDialog;
