import * as React from "react";
import { ConnectDropTarget, ConnectDragSource } from "react-dnd";
import { useTranslation } from "@emisgroup/application-intl";
import {
    PickingListOption,
    NumericEntry,
    CanvasItem,
    ComponentValueCondition,
} from "@emisgroup/clint-templates-common";
import { DragDropSortProps, DragDropSortInstance } from "./addDragDropSort";
import ListBuilderOptionGrid from "./listBuilderOptionGrid";
import ListBuilderOptionButtons from "./listBuilderOptionButtons";

const MANDATORY_PROPERTY_NAMES = ["text"];
const NUMERIC_PROPERTY_NAMES = ["value"];

type ListOptionProps = {
    index: number;
    value: PickingListOption;
    isReadOnly: boolean;
    hasValues: boolean;
    hasFilingData: boolean;
    onUpdate: (value: PickingListOption) => void;
};
const ListOption = ({ index, value, isReadOnly, hasValues, hasFilingData, onUpdate }: ListOptionProps) => {
    const { t } = useTranslation();

    const doUpdate = (propertyName: string, propertyValue: string) => {
        const { [propertyName]: extractedProp, ...existingValue } = value as any;
        const newValue =
            NUMERIC_PROPERTY_NAMES.includes(propertyName) && propertyValue !== ""
                ? Number(propertyValue)
                : propertyValue;
        onUpdate(
            MANDATORY_PROPERTY_NAMES.includes(propertyName) || (newValue !== "" && !Number.isNaN(newValue))
                ? { ...existingValue, [propertyName]: newValue }
                : existingValue,
        );
    };
    const handleChange = (propertyName: string) => (arg: number | React.ChangeEvent<HTMLInputElement>) => {
        const changeEventArg = arg as React.ChangeEvent<HTMLInputElement>;
        doUpdate(propertyName, changeEventArg.currentTarget ? changeEventArg.currentTarget.value : arg.toString());
    };

    const handleBlur = (propertyName: string) => (arg: number | React.FocusEvent<HTMLInputElement>) => {
        const changeEventArg = arg as React.ChangeEvent<HTMLInputElement>;
        doUpdate(
            propertyName,
            changeEventArg.currentTarget ? changeEventArg.currentTarget.value.trim() : arg.toString(),
        );
    };

    return (
        <>
            <input
                className="eui-text-input label-input"
                aria-label={t("stringEntry.enterValueForName")}
                onChange={handleChange("text")}
                onBlur={handleBlur("text")}
                value={value.text}
                readOnly={isReadOnly}
                data-testid={`list-item-input-${index}`}
                disabled={isReadOnly}
            />

            {hasValues && (
                <NumericEntry
                    className="eui-text-input value-input"
                    aria-label={t("stringEntry.enterSubstitutionValueForName")}
                    onChange={handleChange("value")}
                    onBlur={handleBlur("value")}
                    value={value.value}
                    readOnly={isReadOnly}
                    data-testid={`list-item-input-value-${index}`}
                    disabled={isReadOnly}
                />
            )}
            {hasFilingData && (
                <input
                    className="eui-text-input data-input"
                    type="text"
                    aria-label={t("stringEntry.enterFilingDataForName")}
                    onChange={handleChange("data")}
                    onBlur={handleBlur("data")}
                    value={value.data}
                    readOnly={isReadOnly}
                    data-testid={`list-item-input-data-${index}`}
                    disabled={isReadOnly}
                />
            )}
        </>
    );
};

type ListBuilderItemProps = DragDropSortProps & {
    componentId: string;
    className?: string;
    value: PickingListOption;
    isReadOnly: boolean;
    setFocus: boolean;
    hasValues: boolean;
    hasFilingData: boolean;
    componentsActedOn: CanvasItem[];
    onRemove: () => void;
    onUpdate: (value: PickingListOption) => void;
    isDragging: boolean;
    connectDragSource: ConnectDragSource;
    connectDropTarget: ConnectDropTarget;
};

const ListBuilderItem = React.forwardRef<HTMLDivElement, ListBuilderItemProps>(
    (
        {
            componentId,
            className,
            value,
            index,
            isReadOnly,
            setFocus,
            hasValues,
            hasFilingData,
            componentsActedOn,
            onRemove,
            onUpdate,
            isDragging,
            connectDragSource,
            connectDropTarget,
        }: ListBuilderItemProps,
        ref,
    ) => {
        const elementRef = React.useRef<HTMLDivElement>(null);

        if (!isReadOnly) {
            connectDragSource(elementRef);
            connectDropTarget(elementRef);
        }

        const opacity = isDragging ? 0 : 1;

        React.useImperativeHandle<any, DragDropSortInstance>(ref, () => ({
            getNode: () => elementRef.current,
        }));

        React.useEffect(() => {
            if (setFocus && elementRef.current) {
                (elementRef.current.childNodes[0] as HTMLInputElement).focus();
            }
        }, [setFocus]);

        const showVisibilityIndicator = React.useMemo(
            () =>
                componentsActedOn
                    .map(({ rule }) => rule)
                    .filter(
                        rule =>
                            rule?.conditionMembers &&
                            rule.conditionMembers.find((member: ComponentValueCondition) => member.value === value.id),
                    ).length > 0,
            [componentsActedOn, value.id],
        );

        return (
            <div
                ref={elementRef}
                className={`list-builder-item ${!isReadOnly ? "list-builder-item-drag" : ""} ${
                    !isReadOnly && className ? className : ""
                }`}
                style={{ opacity }}
            >
                <ListBuilderOptionGrid hasValues={hasValues} hasFilingData={hasFilingData}>
                    <ListOption
                        index={index}
                        value={value}
                        isReadOnly={isReadOnly}
                        hasValues={hasValues}
                        hasFilingData={hasFilingData}
                        onUpdate={onUpdate}
                    />
                    <ListBuilderOptionButtons
                        isReadOnly={isReadOnly}
                        optionIndex={index}
                        onRemove={onRemove}
                        showVisibilityIndicator={showVisibilityIndicator}
                        componentId={componentId}
                        option={value}
                    />
                </ListBuilderOptionGrid>
            </div>
        );
    },
);

export default ListBuilderItem;
